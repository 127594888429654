import Layzr from 'layzr.js';

let instance;

instance = Layzr({
  threshold: 100
});

update();

function update() {
  instance.update().check().handlers(true);
}

document.addEventListener("lazy_images:update", update);
